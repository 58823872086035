'use client';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import Header from '../Header/Header';
import MobileNavSoMeLink from './MobileNavSoMeLink';
import useModal from '../../hooks/useModal';
import MobileNavLink from './MobileNavLink';
import DarksideToggle from '../DarksideToggle/DarksideToggle';

export interface MobileNavProps {
  children?: React.ReactNode;
  dark?: boolean;
  setTransparentMenu?: boolean;
  hideSocialMedia?: boolean;
  storybook?: boolean;
  bindSetShowMenu?: (alt: { onClick: (val: boolean) => void }) => void;
}

const MobileNav = ({
  children,
  dark,
  setTransparentMenu = false,
  hideSocialMedia = false,
  storybook = false,
  bindSetShowMenu,
}: MobileNavProps) => {
  const useAlternativeMenu = !!children;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  useModal(showMenu);

  const menuButtonClasses = classNames({
    'transition-bg-50ms': true,
    'bg-blankBlack': !dark,
    'bg-blankYellow': dark,
  });

  const transparentMenuClass = !setTransparentMenu
    ? 'bg-blankYellow px-[2px] py-[4px]'
    : '';

  const handleClick = (val) => {
    setShowMenu(val);
  };

  useEffect(() => {
    if (useAlternativeMenu) {
      bindSetShowMenu({ onClick: handleClick });
    }
  }, [bindSetShowMenu, useAlternativeMenu]);

  const renderDarksideToggle = !showMenu;
  return (
    <>
      <div
        className={`flex laptop:hidden ${transparentMenuClass} transition-bg-1s space-x-[52px] pointer-events-auto`}
      >
        <button onClick={() => setShowMenu(true)}>
          <div className="flex flex-col">
            <div
              className={`h-[1px] w-32 mb-[18px] ${menuButtonClasses}`}
            ></div>
            <div className={`h-[1px] w-32 ${menuButtonClasses}`}></div>
          </div>
        </button>
        {renderDarksideToggle && !storybook && <DarksideToggle />}
      </div>
      {showMenu && (
        <div className="fixed overflow-auto top-0 left-0 h-full w-full bg-[#272D2A] z-20 pointer-events-auto">
          <Header forceDark setFixed={false}>
            <div className="flex flex-row space-x-[52px]">
              <button
                className="relative w-32 h-32 pointer-events-auto"
                onClick={() => setShowMenu(false)}
              >
                <div className="absolute h-[1px] w-32 bg-[#FFFCB6] rotate-45 animate-rotateLeft"></div>
                <div className="absolute h-[1px] w-32 bg-[#FFFCB6] rotate-[-45deg] animate-rotateRight"></div>
              </button>
              {!storybook && <DarksideToggle forceLight={true} />}
            </div>
          </Header>
          <div className="flex flex-col items-end mb-[152px] pr-48 pl-16 justify-between">
            <div className="grid mt-24 place-items-end">
              {useAlternativeMenu ? (
                <>{children}</>
              ) : (
                <>
                  <MobileNavLink
                    href="/digital-produktutvikling"
                    onClick={() => setShowMenu(false)}
                  >
                    om oss
                  </MobileNavLink>
                  <MobileNavLink
                    href="/prosjekter"
                    onClick={() => setShowMenu(false)}
                  >
                    portfolio
                  </MobileNavLink>
                  <MobileNavLink
                    href="/jobb"
                    onClick={() => setShowMenu(false)}
                  >
                    jobb i Blank
                  </MobileNavLink>
                  <MobileNavLink
                    href="/menneskene"
                    onClick={() => setShowMenu(false)}
                  >
                    menneskene
                  </MobileNavLink>
                  <MobileNavLink
                    href="/handboka"
                    onClick={() => setShowMenu(false)}
                  >
                    håndboka
                  </MobileNavLink>
                  <button
                    className="underline font-respira text-[40px] tablet:text-plakatSmall leading-blankSnug tracking-blankTight text-[#FFFCB6] mb-8 last:mb-0 hover:text-[#FFFCB6] hover:bg-[#0000FF]"
                    onClick={() => {
                      setShowMenu(false);
                      document.getElementById('contactUs')?.scrollIntoView();
                    }}
                  >
                    kontakt
                  </button>
                </>
              )}
            </div>
            {!hideSocialMedia && (
              <div className="grid mt-32">
                <MobileNavSoMeLink href="https://blogg.blank.no/">
                  blogg
                </MobileNavSoMeLink>
                <MobileNavSoMeLink href="https://www.facebook.com/blankoslo">
                  facebook
                </MobileNavSoMeLink>
                <MobileNavSoMeLink href="https://www.instagram.com/blankoslo/">
                  instagram
                </MobileNavSoMeLink>
                <MobileNavSoMeLink href="https://no.linkedin.com/company/blankas">
                  linkedIn
                </MobileNavSoMeLink>
                <MobileNavSoMeLink href="/events">kjøregår</MobileNavSoMeLink>
              </div>
            )}

            <div className="mt-[120px]">
              <Link href="/" passHref legacyBehavior>
                <div
                  className="h-[12.5px] w-[50px] bg-[#FFFCB6] cursor-pointer"
                  onClick={() => setShowMenu(false)}
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
